/// <reference types="vite/client" />
import { startStimulusApplication } from '@betterment/institutional';
import * as scLegacyInitializers from '@betterment/style-closet-legacy';
import jQuery from 'jquery';
import * as Rails from '@rails/ujs';
import 'jquery-pjax';
import 'jquery-ui';
import 'bootstrap';
import 'lodash';
import 'qtip2';
import '@selectize/selectize';

import '../vendor/alert.js';
import '../vendor/highlight.pack.js';
import '../vendor/jquery-readyselector.js';
import '../vendor/jquery.browser.js';
import '../vendor/jQuerySpinnerPlugin.js';
import '../vendor/typed-jquery.js';
import '../src/form_behaviors/actionMenuBehavior.js';
import '../src/form_behaviors/compositeModifier.js';
import '../src/form_behaviors/compositeSecondaryAdvisor.js';
import '../src/form_behaviors/fileInputBehavior.js';
import '../src/form_behaviors/formRefresh.js';
import '../src/form_behaviors/injectForm.js';
import '../src/form_behaviors/inputBehavior.js';
import '../src/form_behaviors/radioBehavior.js';
import '../src/form_behaviors/removeElementBehavior.js';
import '../src/analytics.js';
import '../src/errors.js';
import '../src/modal_auto_open.js';
import '../src/pjax.js';
import '../src/spinner.js';

window.$ = jQuery;
window.jQuery = jQuery;

declare global {
  interface Window {
    $: typeof jQuery;
    jQuery: typeof jQuery;
  }
}

import.meta.glob('../images', { eager: true });

for (const initializer in scLegacyInitializers) {
  scLegacyInitializers[initializer]();
}

if (!window._rails_loaded) Rails.start();

startStimulusApplication();
